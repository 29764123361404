// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import flowRight from 'lodash/flowRight';
import { NextPage } from 'next';
import { withApollo } from '~/common/apollo';
import { withSSAppRedirect } from '~/common/apollo/withProps/withSSAppRedirect';
import { withSSCookie } from '~/common/apollo/withProps/withSSCookie';
import { SEO } from '~/containers/common/SEO';
import { LandingPage } from '~/containers/pages/LandingPage/LandingPage';

const Page: NextPage = () => {
  return (
    <>
      <SEO
        canonical={'https://www.nipht.io'}
        description="Stop hoarding countless bookmarks only to struggle to find what you need. Niphtio will simplify the way you save, find, and read what truly matters to you."
      />
      <LandingPage />
    </>
  );
};

export const getServerSideProps = flowRight(withSSCookie, withSSAppRedirect)();

export default flowRight(withApollo)(Page);
