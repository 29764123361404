// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import {
  Box,
  Button,
  Flex,
  FlexProps,
  forwardRef,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { check_webp_feature } from '~/lib/webp';

interface Props extends FlexProps {
  /** Callback function for sign up button */
  onSignUpClick?: () => void;
}

/**
 * Beta call to action row
 * Returns a designed section with a button to sign up
 */
export const BetaCtaRow = forwardRef<Props, 'div'>(
  ({ onSignUpClick, ...props }, ref) => {
    const [bgImages, setbgImages] = useState([
      'url(/landing/hero-phone-stack-left.webp)',
    ]);

    useEffect(() => {
      check_webp_feature('alpha').catch(() => {
        // When webp is not supported switch back to using png
        setbgImages((images) =>
          images.map((i) => i.replaceAll('.webp', '.png')),
        );
      });
    });

    return (
      <Flex
        ref={ref}
        h={'xs'}
        backgroundColor="npDust.500"
        overflow="hidden"
        justifyContent="center"
        backgroundSize={['cover']}
        className="lazyload hero"
        pos={'relative'}
        {...props}
      >
        <Box
          backgroundImage={bgImages}
          backgroundRepeat="no-repeat"
          backgroundPosition={'right'}
          backgroundSize={'100%'}
          pos={'absolute'}
          top="0"
          bottom={0}
          height="100%"
          width="800px"
          right={['-400px', '-400px', '-200px']}
          zIndex={2}
        ></Box>
        <Flex
          mt={-8}
          maxW="5xl"
          justifyContent="center"
          alignItems="center"
          flex="auto"
          zIndex={3}
        >
          <Box w="50%" px={4}>
            <Box w={['2xs', 'md']} justifyContent="end" flexDirection="column">
              <Stack alignItems="start" spacing={6}>
                <Heading>Start organizing today</Heading>
                <Button variant="npPrimary" size="md" onClick={onSignUpClick}>
                  Sign up for free
                </Button>
              </Stack>
            </Box>
          </Box>
          <Box w="50%" h="100%" />
        </Flex>
      </Flex>
    );
  },
);
