// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { Box } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { isMobileSafari } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';

interface Props
  extends React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
  > {
  threshold?: number;
  triggerOnce?: boolean;
}

/**
 * This component will load a video only when the viewport goes over this element.
 *
 * Usage:
 *  <LazyLoadVideo>
 *    <source src='/browser-extension.mp4' type="video/mp4" />
 *  </LazyLoadVideo>
 *
 * @param {string} threshold percentage of viewport over element before triggering.
 * @param {boolean} trigggerOnce is set to false if you want the element to disappear once the viewport
 * moves away from the element.
 */
export const LazyLoadVideo: FC<Props> = ({
  children,
  threshold,
  triggerOnce,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);

  const { ref, inView } = useInView({
    threshold: threshold ?? 0.25,
    triggerOnce,
  });

  useEffect(() => {
    if (inView) {
      setLoaded(true);
    }
  }, [inView, setLoaded]);

  // bug fix: allow controls in mobile safari
  return (
    <video
      style={{ maxHeight: '100%' }}
      controls={isMobileSafari}
      ref={ref}
      muted
      autoPlay
      loop
      {...props}
    >
      {/* first source is default */}
      {loaded && children}
    </video>
  );
};
