// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, Heading, Link } from '@chakra-ui/react';
import { FC } from 'react';
import { MdArrowRightAlt } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons';

export const LandingLink: FC<{ url: string; text: string }> = ({
  url,
  text,
}) => {
  return (
    <Link target="_blank" href={url} variant="npAccent">
      <Flex mt={2} alignItems="center">
        <Heading fontWeight={600} fontSize="sm">
          {text}
        </Heading>
        <ReactIcon boxSize="20px" as={MdArrowRightAlt}></ReactIcon>
      </Flex>
    </Link>
  );
};
