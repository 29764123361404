// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { MdOutlineStar } from 'react-icons/md';

const Stars = () => {
  return (
    <Flex>
      <MdOutlineStar />
      <MdOutlineStar />
      <MdOutlineStar />
      <MdOutlineStar />
      <MdOutlineStar />
    </Flex>
  );
};

const ReviewItems = () => {
  return (
    <>
      <Box p={4} minW="sm" border="1px solid #E2E8F0" borderRadius={6}>
        <Flex gap={2} flexDir={'row'} alignItems={'center'}>
          <Avatar color="black" size="sm" bg="blue.300" name="cavejello" />
          <Text>cavejellos</Text> <Stars />
        </Flex>
        <Text fontSize={'sm'}>
          “Simple and effective bookmarking tool. Highly recommended for
          disorganized folks like me.”
        </Text>
      </Box>
      <Box p={4} minW="sm" border="1px solid #E2E8F0" borderRadius={6}>
        <Flex gap={2} flexDir={'row'} alignItems={'center'}>
          <Avatar
            size="sm"
            name="River Saxton"
            src="/landing/Avatar02@2x.png"
          />
          <Text>River Saxton</Text> <Stars />
        </Flex>
        <Text fontSize={'sm'}>
          “Fantastic extension, all function, no filler. Minimalistic in all the
          right ways without sacrificing ease of use.”
        </Text>
      </Box>
      <Box p={4} minW="sm" border="1px solid #E2E8F0" borderRadius={6}>
        <Flex gap={2} flexDir={'row'} alignItems={'center'}>
          <Avatar
            size="sm"
            name="Kazuki Nakayashiki"
            src="/landing/Avatar03@2x.png"
          />
          <Text>Kazuki Nakayashiki</Text> <Stars />
        </Flex>
        <Text fontSize={'sm'}>
          “Love how it works! Thank you for building ;)”
        </Text>
      </Box>
      <Box p={4} minW="sm" border="1px solid #E2E8F0" borderRadius={6}>
        <Flex gap={2} flexDir={'row'} alignItems={'center'}>
          <Avatar color="black" size="sm" bg="blue.300" name="J" />
          <Text>J.P. Carroll</Text> <Stars />
        </Flex>
        <Text fontSize={'sm'}>
          “love Niphtio, makes organization a breeze. very intuitive design!”
        </Text>
      </Box>
      <Box p={4} minW="sm" border="1px solid #E2E8F0" borderRadius={6}>
        <Flex gap={2} flexDir={'row'} alignItems={'center'}>
          <Avatar size="sm" />
          <Text>Anonymous</Text>
        </Flex>
        <Text fontSize={'sm'}>
          “Super easy to use and absolutely clutch for quickly adding things to
          a list to come back to without having to think too hard about it.”
        </Text>
      </Box>
    </>
  );
};
export const Reviews = () => {
  return (
    <Box maxW={'5xl'} overflow={'hidden'}>
      <motion.div
        animate={{
          x: ['0%', '-100%'], // Start from full container width and scroll left
        }}
        transition={{
          duration: 50, // Adjust for speed
          ease: 'linear',
          repeat: Infinity, // Loop infinitely
        }}
      >
        <Flex gap={2} flexDir={'row'}>
          <ReviewItems></ReviewItems>
          <ReviewItems></ReviewItems>
        </Flex>
      </motion.div>
    </Box>
  );
};
