// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface Props extends BoxProps {
  triggerOnce: boolean;
  threshold?: number;
}

/** Fade component will fade in when the viewport is over this item. */
export const Fade = forwardRef<Props, 'div'>(
  ({ children, triggerOnce, threshold, ...boxProps }, _) => {
    const { ref, inView } = useInView({
      threshold: threshold ?? 0.25,
      triggerOnce,
    });

    const props = { opacity: inView ? 1 : 0 };

    return (
      <Box as={motion.div} {...boxProps} animate={props} ref={ref}>
        {children}
      </Box>
    );
  },
);
