// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import { LazyLoadVideo } from '~/components/LazyLoad';

export interface Props extends PropsWithChildren {
  image?: string;
  alt?: string;
  mp4?: string;
  video?: boolean;
  poster?: string;
  header: React.ReactNode | string;
  subheader: React.ReactNode | string;
  left: boolean;
  description?: string;
}

export const Section: FC<Props> = ({
  children,
  image,
  alt,
  video,
  mp4,
  left,
  header,
  subheader,
  description,
  poster,
}) => {
  const MediaComponent = (
    <Flex
      order={[left ? 1 : -1, 'inherit']}
      margin="auto"
      flex="auto"
      position="relative"
    >
      <Box maxW="lg" m="auto" position="relative">
        {image && (
          <Image
            position="relative"
            maxH={['326px', '500px', '527px']}
            src={image}
            alt={alt || ''}
          />
        )}
        {video == true && (
          <Box
            maxH={['inherit', '500px', '527px']}
            boxShadow={`-0.834612px 3.33845px 5.00767px rgba(0, 0, 0, 0.15)`}
          >
            <LazyLoadVideo
              poster={poster}
              style={{ maxHeight: '500px', maxWidth: '100%' }}
            >
              {/* first source is default */}
              {mp4 && <source src={mp4} type="video/mp4" />}
            </LazyLoadVideo>
          </Box>
        )}
      </Box>
    </Flex>
  );

  return (
    <Flex
      w="100%"
      alignItems="center"
      justifyContent="center"
      maxWidth={['100%', '5xl']}
      mx="auto"
    >
      <Flex
        justifyContent="space-between"
        flexDir={[
          left ? 'column-reverse' : 'column',
          left ? 'column-reverse' : 'column',
          'row',
        ]}
        w="100%"
      >
        {left && MediaComponent}
        <Flex
          position="relative"
          zIndex={1}
          px={4}
          flexDir="column"
          justifyContent="center"
          alignItems={['center', 'center']}
          mt={[12, 0]}
          ml={left ? [0, 0, 12, 20] : 0}
          mr={left ? 0 : [0, 0, 12, 20]}
        >
          <Box mb={5} maxW={['inherit', 'lg', 'xs']}>
            <Heading
              lineHeight="shorter"
              mb={1}
              fontWeight="bold"
              fontSize="3xl"
            >
              {header}
            </Heading>
            <Text display="inline" lineHeight="base" fontSize="sm">
              {subheader}
            </Text>

            {description && (
              <Text display="inline" lineHeight="base" fontSize="md" mt={5}>
                {description}
              </Text>
            )}
            {children}
          </Box>
        </Flex>
        {!left && MediaComponent}
      </Flex>
    </Flex>
  );
};
