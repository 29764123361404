import { Button, ButtonProps, Link } from '@chakra-ui/react';

export const CTA = (props: ButtonProps) => {
  return (
    <Link href="/signup">
      <Button {...props} variant="npPrimary" fontSize="md" size="lg">
        Create my free library
      </Button>
    </Link>
  );
};
