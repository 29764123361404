// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { Box, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { LandingLink } from './LandingLink';

export const InfoBanner: FC = () => {
  return (
    <SimpleGrid justifyContent="center" columns={[1, 1, 3]} maxW="5xl">
      <Box px={6} py={10}>
        <Heading fontSize="2xl">Share your bookmarks</Heading>

        <Box mt={2}>
          <Text lineHeight="short" fontSize="sm">
            Share a collection of bookmarks that automatically updates whenever
            you add bookmarks to it.
          </Text>
          <LandingLink
            url="/guides/shared-collections"
            text="Shared collections"
          ></LandingLink>
        </Box>
      </Box>

      <Box px={6} py={10}>
        <Text fontWeight="bold" fontSize="2xl">
          Save multiple tabs at once
        </Text>

        <Box mt={2}>
          <Text lineHeight="short" fontSize="sm">
            Save all your open tabs with one click.
          </Text>
          <LandingLink
            url="/guides/save-multiple-tabs"
            text="Multi-tab saver"
          ></LandingLink>
        </Box>
      </Box>

      <Box px={6} py={10}>
        <Text fontWeight="bold" fontSize="2xl">
          Notes
        </Text>

        <Box mt={2}>
          <Text lineHeight="short" fontSize="sm">
            Jot down key takeaways or remind yourself why you saved something.
          </Text>
          <LandingLink
            url="/guides/add-and-use-notes"
            text="Notes"
          ></LandingLink>
        </Box>
      </Box>

      <Box px={6} py={10}>
        <Text fontWeight="bold" fontSize="2xl">
          Access from any device
        </Text>

        <Box mt={2}>
          <Text lineHeight="short" fontSize="sm">
            Access Niphtio from any web browser on your computer, tablet, or
            phone.
          </Text>
        </Box>
      </Box>
      <Box px={6} py={10}>
        <Text fontWeight="bold" fontSize="2xl">
          Distraction-free reading
        </Text>

        <Box mt={2}>
          <Text lineHeight="short" fontSize="sm">
            Enjoy a clean reading experience, free of distractions.
          </Text>
        </Box>
      </Box>
      <Box px={6} py={10}>
        <Text fontWeight="bold" fontSize="2xl">
          Stay organized with collections
        </Text>

        <Box mt={2}>
          <Text lineHeight="short" fontSize="sm">
            We get it—some bookmarks belong to more than one category. Niphtio
            lets you put a single bookmark into multiple collections.
          </Text>
          <LandingLink
            url="/guides/stay-organized-with-tags"
            text="Get organized with collections"
          ></LandingLink>
        </Box>
      </Box>
      <Box px={6} py={10}>
        <Text fontWeight="bold" fontSize="2xl">
          Dark mode
        </Text>

        <Box mt={2}>
          <Text lineHeight="short" fontSize="sm">
            Read comfortably on both desktop and mobile web.
          </Text>
        </Box>
      </Box>
      <Box px={6} py={10}>
        <Text fontWeight="bold" fontSize="2xl">
          To do list
        </Text>

        <Box mt={2}>
          <Text lineHeight="short" fontSize="sm">
            Keep track of important reads with your to do list and check them
            off when you’re done.
          </Text>
        </Box>
      </Box>
      <Box px={6} py={10}>
        <Text fontWeight="bold" fontSize="2xl">
          Built with privacy in mind
        </Text>

        <Box mt={2}>
          <Text lineHeight="short" fontSize="sm">
            We don&apos;t use tracking cookies, sell any of your personal
            information, or share it with anyone else.
          </Text>
        </Box>
      </Box>
    </SimpleGrid>
  );
};
