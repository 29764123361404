// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Button, Flex, Heading, Link, Stack } from '@chakra-ui/react';

import { MdArrowRightAlt } from 'react-icons/md';
import { MobileButtons } from './MobileButtons';

export const LandingStack = () => {
  return (
    <Flex
      px={[3, 3, 20]}
      py={20}
      bgColor={'npBrandedBg'}
      w={['100%', '100%', '5xl']}
      alignItems={'center'}
      flexDir={'column'}
      gap={6}
      borderRadius={'xl'}
    >
      <Heading fontSize={'5xl'} pb={4} textAlign={'center'}>
        Let&apos;s tame your bookmark chaos.
      </Heading>
      <Stack gap={6}>
        <Link href="/signup">
          <Button
            w={'full'}
            size="lg"
            variant={'npOutline'}
            rightIcon={<MdArrowRightAlt />}
          >
            Continue on web
          </Button>
        </Link>

        <MobileButtons />
      </Stack>
    </Flex>
  );
};
