// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Image, Link, Stack } from '@chakra-ui/react';
import { appleStoreAppUrl } from '~/common/links';

export const MobileButtons = () => {
  return (
    <Stack direction="row">
      <Link
        href={appleStoreAppUrl}
        aria-label="Download ios app"
        isExternal={true /** Leave as external to track usage event */}
      >
        <Image
          h="40px"
          src="/landing/app-store-badge@2x.png"
          alt="App store badge"
        />
      </Link>
      <Link
        href="/android"
        aria-label="Download android app"
        isExternal={true /** Leave as external to track usage event */}
      >
        <Image
          h="40px"
          src="/landing/play-store-badge@2x.png"
          alt="Android store badge"
        />
      </Link>
    </Stack>
  );
};
